<template>
    <div class="container">
        <div class="title">系统消息</div>
        <div v-if="newsList != 0">
            <div class="news" v-for="(item, index) in newsList" :key="index">
                <div class="news-l">
                    <img src="../../assets/xiaoxi_icon_xitong@3x.png" alt="" class="news-l_img" />
                    <!-- <el-avatar
                        :size="46"
                        src="../../assets/xiaoxi_icon_xitong@3x.png"
                    ></el-avatar> -->
                    <div class="news-con">
                        <div class="txt">
                            <div class="txt1">系统消息</div>
                            <div class="txt2">{{ item.createdAt }}</div>
                        </div>
                        <div class="txt3">{{ item.content }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty" v-else>
            <i class="el-icon-takeaway-box" style="margin-left: 29px"></i>
            <div style="font-size: 30px">暂无信息</div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            newsList: []
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    created() {
        const receiveUserId = this.userInfo.id;
        this.$http
            .post(
                '/information/all',
                { size: 1000, query: { receiveUserId }, sort: 'createdAt,desc' },
                { body: 'json' }
            )
            .then(res => {
                this.newsList = res.content;
            });
    },
    methods: {}
};
</script>

<style lang="less" scoped>
.container {
    margin: 17px 20px 20px;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        padding-bottom: 17px;
        line-height: 26px;
        border-bottom: 1px solid #f2f3f5;
    }
    .empty {
        position: absolute;
        top: 50%;
        left: 53%;
        font-size: 60px;
        color: #ccc;
    }
    .news {
        margin-top: 20px;
        display: flex;
        .news-l {
            display: flex;
            .news-con {
                padding-left: 10px;
                .txt {
                    .flex();
                    margin-bottom: 5px;
                    .txt1 {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: bold;
                        color: #000000;
                    }
                    .txt2 {
                        font-size: 14px;
                        font-weight: 400;
                        color: #c8c9cc;
                        line-height: 24px;
                        margin-left: 30px;
                    }
                }
                .txt3 {
                    background: #f5f7fa;
                    border-radius: 0px 8px 8px 8px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #000;
                    padding: 5px 10px;
                    line-height: 24px;
                }
            }
        }
    }
}
.news-l_img {
    width: 46px;
    height: 46px;
}
</style>
